import React from "react"
import theme from "theme"
import { Theme, Text, Hr, Box, Section, Image, Link } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>
          I nostri servizi | Esplora i nostri servizi presso Honda Point ATV
          Rentals
        </title>
        <meta name={"description"} content={"La tua avventura inizia qui!"} />
        <meta
          property={"og:title"}
          content={
            "I nostri servizi | Esplora i nostri servizi presso Honda Point ATV Rentals"
          }
        />
        <meta
          property={"og:description"}
          content={"La tua avventura inizia qui!"}
        />
        <meta
          property={"og:image"}
          content={"https://fovixelusxiz.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://fovixelusxiz.com/img/8397436.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://fovixelusxiz.com/img/8397436.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://fovixelusxiz.com/img/8397436.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://fovixelusxiz.com/img/8397436.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://fovixelusxiz.com/img/8397436.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://fovixelusxiz.com/img/8397436.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="url(https://fovixelusxiz.com/img/1.jpg) center/cover"
        padding="140px 0"
        sm-padding="40px 0"
        quarkly-title="About-16"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          justify-content="center"
          sm-min-width="280px"
        />
        <Box
          padding="50px 80px 50px 50px"
          color="--dark"
          display="flex"
          flex-direction="column"
          align-items="center"
          max-width="800px"
          background="#ffffff"
          sm-padding="50px 24px 50px 24px"
        >
          <Text margin="0 0 16px 0" as="h1" font="--headline2">
            Cosa offriamo
          </Text>
          <Hr
            min-height="10px"
            margin="0px 0px 0px 0px"
            border-color="--color-dark"
            border-width="3px 0 0 0"
            max-width="50px"
            width="100%"
          />
          <Text font="--base" text-align="center">
            Noi di Honda Point ATV Rentals ci dedichiamo a fornirvi
            un'esperienza fuoristrada senza precedenti. La nostra gamma completa
            di servizi assicura che ogni aspetto della vostra avventura sia
            curato. Che siate alla ricerca di una scarica di adrenalina o di un
            tranquillo viaggio nella natura, abbiamo qualcosa per tutti.
            Esplorate i nostri servizi e scoprite come possiamo rendere
            indimenticabile la vostra prossima avventura in ATV.
          </Text>
        </Box>
      </Section>
      <Section
        background="#ffffff"
        padding="90px 0 110px 0"
        quarkly-title="Team-12"
      >
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          flex-direction="column"
          lg-width="100%"
          grid-template-columns="repeat(3, 1fr)"
          grid-template-rows="repeat(2, 1fr)"
          grid-gap="46px 24px"
          sm-grid-template-columns="1fr"
          sm-grid-template-rows="auto"
          sm-grid-gap="36px 0"
          margin="50px 0px 0px 0px"
          lg-grid-template-columns="repeat(2, 1fr)"
          lg-grid-template-rows="auto"
        >
          <Box
            min-width="100px"
            min-height="100px"
            justify-self="center"
            sm-display="flex"
            sm-flex-direction="column"
            sm-align-items="center"
            display="flex"
            flex-direction="column"
          >
            <Box
              min-width="100px"
              min-height="100px"
              sm-width="100%"
              sm-margin="0px 0px 0 0px"
            >
              <Image
                src="https://fovixelusxiz.com/img/3.jpg"
                display="block"
                width="100%"
                border-radius="25px"
                lg-max-width="100%"
                max-width="100%"
                object-fit="cover"
                max-height="100%"
                md-height="250px"
                md-width="auto"
                sm-width="100%"
                sm-height="auto"
                height="250px"
              />
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              justify-content="center"
              sm-width="100%"
              sm-padding="0px 0px 0px 0"
              margin="25px 0px 0px 0px"
            >
              <Text
                color="--dark"
                font="600 18px --fontFamily-sans"
                lg-margin="0 0px 0px 0px"
                margin="0 0px 0 0px"
              >
                Noleggio ATV
              </Text>
              <Text
                margin="8px 0px 0px 0px"
                color="--greyD2"
                font="300 18px/140% --fontFamily-sansHelvetica"
              >
                ATV per principianti: Modelli facili da maneggiare, perfetti per
                i piloti alle prime armi.
                <br />
                <br />
                ATV avanzati: veicoli ad alta potenza progettati per piloti
                esperti in cerca di emozioni forti.
                <br />
                <br />
                ATV per famiglie: Modelli spaziosi ideali per le avventure in
                famiglia, che garantiscono comfort e sicurezza per tutte le età.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            justify-self="center"
            sm-display="flex"
            sm-flex-direction="column"
            sm-align-items="center"
            display="flex"
            flex-direction="column"
          >
            <Box
              min-width="100px"
              min-height="100px"
              sm-width="100%"
              sm-margin="0px 0px 0 0px"
            >
              <Image
                src="https://fovixelusxiz.com/img/4.jpg"
                display="block"
                width="100%"
                border-radius="25px"
                lg-max-width="100%"
                max-width="100%"
                object-fit="cover"
                max-height="100%"
                md-height="250px"
                md-width="auto"
                sm-width="100%"
                sm-height="auto"
                height="250px"
              />
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              justify-content="center"
              sm-width="100%"
              sm-padding="0px 0px 0px 0"
              margin="25px 0px 0px 0px"
            >
              <Text
                color="--dark"
                font="600 18px --fontFamily-sans"
                lg-margin="0 0px 0px 0px"
                margin="0 0px 0 0px"
              >
                Tour guidati
              </Text>
              <Text
                margin="8px 0px 0px 0px"
                color="--greyD2"
                font="300 18px/140% --fontFamily-sansHelvetica"
              >
                Tour della natura: Esplorate i sentieri sereni che mostrano la
                bellezza della natura circostante.
                <br />
                <br />
                Tour d'avventura: Affrontate terreni impegnativi con la guida
                dei nostri esperti accompagnatori.
                <br />
                <br />
                Tour personalizzati: Personalizzate il vostro viaggio con
                itinerari personalizzati in base alle vostre preferenze e al
                vostro livello di abilità.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            justify-self="center"
            sm-display="flex"
            sm-flex-direction="column"
            sm-align-items="center"
            display="flex"
            flex-direction="column"
          >
            <Box
              min-width="100px"
              min-height="100px"
              sm-width="100%"
              sm-margin="0px 0px 0 0px"
            >
              <Image
                src="https://fovixelusxiz.com/img/5.jpg"
                display="block"
                width="100%"
                border-radius="25px"
                lg-max-width="100%"
                max-width="100%"
                object-fit="cover"
                max-height="100%"
                md-height="250px"
                md-width="auto"
                sm-width="100%"
                sm-height="auto"
                height="250px"
              />
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              justify-content="center"
              sm-width="100%"
              sm-padding="0px 0px 0px 0"
              margin="25px 0px 0px 0px"
            >
              <Text
                color="--dark"
                font="600 18px --fontFamily-sans"
                lg-margin="0 0px 0px 0px"
                margin="0 0px 0 0px"
              >
                Formazione sulla sicurezza
              </Text>
              <Text
                margin="8px 0px 0px 0px"
                color="--greyD2"
                font="300 18px/140% --fontFamily-sansHelvetica"
              >
                Formazione di base sulla sicurezza: Imparare le pratiche di
                sicurezza essenziali e le tecniche di guida per un'esperienza
                sicura.
                <br />
                <br />
                Formazione avanzata: Migliorate le vostre abilità con tecniche e
                manovre avanzate per le corse più impegnative.
                <br />
                <br />
                Corsi di aggiornamento: Sessioni di formazione rapida per
                ripassare i protocolli di sicurezza e le abilità di guida.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            justify-self="center"
            sm-display="flex"
            sm-flex-direction="column"
            sm-align-items="center"
            display="flex"
            flex-direction="column"
          >
            <Box
              min-width="100px"
              min-height="100px"
              sm-width="100%"
              sm-margin="0px 0px 0 0px"
            >
              <Image
                src="https://fovixelusxiz.com/img/6.jpg"
                display="block"
                width="100%"
                border-radius="25px"
                lg-max-width="100%"
                max-width="100%"
                object-fit="cover"
                max-height="100%"
                md-height="250px"
                md-width="auto"
                sm-width="100%"
                sm-height="auto"
                height="250px"
              />
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              justify-content="center"
              sm-width="100%"
              sm-padding="0px 0px 0px 0"
              margin="25px 0px 0px 0px"
            >
              <Text
                color="--dark"
                font="600 18px --fontFamily-sans"
                lg-margin="0 0px 0px 0px"
                margin="0 0px 0 0px"
              >
                Noleggio di attrezzature
              </Text>
              <Text
                margin="8px 0px 0px 0px"
                color="--greyD2"
                font="300 18px/140% --fontFamily-sansHelvetica"
              >
                Caschi: caschi di alta qualità per garantire sicurezza e
                comfort.
                <br />
                <br />
                Equipaggiamento protettivo: Armatura integrale, guanti e
                ginocchiere per una maggiore protezione.
                <br />
                <br />
                Strumenti di navigazione: Dispositivi GPS e mappe dei sentieri
                per mantenere la rotta durante il viaggio.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            justify-self="center"
            sm-display="flex"
            sm-flex-direction="column"
            sm-align-items="center"
            display="flex"
            flex-direction="column"
          >
            <Box
              min-width="100px"
              min-height="100px"
              sm-width="100%"
              sm-margin="0px 0px 0 0px"
            >
              <Image
                src="https://fovixelusxiz.com/img/7.jpg"
                display="block"
                width="100%"
                border-radius="25px"
                lg-max-width="100%"
                max-width="100%"
                object-fit="cover"
                max-height="100%"
                md-height="250px"
                md-width="auto"
                sm-width="100%"
                sm-height="auto"
                height="250px"
              />
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              justify-content="center"
              sm-width="100%"
              sm-padding="0px 0px 0px 0"
              margin="25px 0px 0px 0px"
            >
              <Text
                color="--dark"
                font="600 18px --fontFamily-sans"
                lg-margin="0 0px 0px 0px"
                margin="0 0px 0 0px"
              >
                Mappe dei sentieri
              </Text>
              <Text
                margin="8px 0px 0px 0px"
                color="--greyD2"
                font="300 18px/140% --fontFamily-sansHelvetica"
              >
                Percorsi per principianti: Percorsi facili, perfetti per i
                neofiti della guida di ATV.
                <br />
                <br />
                Percorsi intermedi: Percorsi moderati per chi vuole sfidare un
                po' di più.
                <br />
                <br />
                Percorsi avanzati: Percorsi difficili per cavalieri esperti in
                cerca di adrenalina.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            justify-self="center"
            sm-display="flex"
            sm-flex-direction="column"
            sm-align-items="center"
            display="flex"
            flex-direction="column"
          >
            <Box
              min-width="100px"
              min-height="100px"
              sm-width="100%"
              sm-margin="0px 0px 0 0px"
            >
              <Image
                src="https://fovixelusxiz.com/img/8.jpg"
                display="block"
                width="100%"
                border-radius="25px"
                lg-max-width="100%"
                max-width="100%"
                object-fit="cover"
                max-height="100%"
                md-height="250px"
                md-width="auto"
                sm-width="100%"
                sm-height="auto"
                height="250px"
              />
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              justify-content="center"
              sm-width="100%"
              sm-padding="0px 0px 0px 0"
              margin="25px 0px 0px 0px"
            >
              <Text
                color="--dark"
                font="600 18px --fontFamily-sans"
                lg-margin="0 0px 0px 0px"
                margin="0 0px 0 0px"
              >
                Servizi di manutenzione
              </Text>
              <Text
                margin="8px 0px 0px 0px"
                color="--greyD2"
                font="300 18px/140% --fontFamily-sansHelvetica"
              >
                Manutenzione ordinaria: Controlli e assistenza regolari per
                garantire prestazioni ottimali.
                <br />
                <br />
                Riparazioni: Servizi di riparazione da parte di esperti per
                qualsiasi problema o danno.
                <br />
                <br />
                Aggiornamenti: Migliorate il vostro ATV con le parti e gli
                accessori più recenti.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Box
          padding="80px 72px 80px 72px"
          background="#dddddd"
          border-radius="24px"
          margin="0px 0px 64px 0px"
          sm-padding="60px 36px 60px 36px"
        >
          <Box
            width="100%"
            display="flex"
            flex-direction="column"
            md-width="100%"
            md-align-items="center"
            md-justify-content="center"
            md-text-align="center"
            lg-width="100%"
          >
            <Text
              margin="0px 0px 16px 0px"
              font="--headline2"
              color="--dark"
              md-text-align="center"
              sm-font='normal 700 32px/1.2 "Source Sans Pro", sans-serif'
            >
              Contattateci per maggiori informazioni
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--base"
              color="--greyD3"
              md-text-align="center"
            >
              Per saperne di più sui nostri servizi e su come possiamo aiutarvi
              a creare l'avventura perfetta, non esitate a contattarci o a
              visitare la nostra sede. Il nostro team è sempre pronto a fornirvi
              informazioni dettagliate e ad assistervi nella pianificazione
              della vostra prossima esperienza fuoristrada.
              <br />
              <br />
              Noi di Honda Point ci impegniamo a rendere la vostra avventura in
              ATV sicura, emozionante e memorabile. Scoprite il brivido della
              corsa con noi ed esplorate i grandi spazi aperti come mai prima
              d'ora.
            </Text>
          </Box>
          <Link
            href="/contacts"
            color="#000000"
            padding="11px 45px 12px 45px"
            background="--color-primary"
            margin="50px 0px 0px 0px"
            display="inline-block"
            border-radius="8px"
          >
            {" "}
            Contatti
          </Link>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
